import { Component, Inject, Vue } from 'vue-property-decorator';
import axios from 'axios';
@Component
export default class EntitiesMenu extends Vue {
  public list: [] = [];
  public mounted(): void {
    this.retrieveMenuPermission(this.$store.getters.account?.authorities);
  }

  public retrieveMenuPermission(role: []): Promise<boolean> {
    return new Promise(resolve => {
      axios
        .get<any>('api/menu_permission?role_name=' + role)
        .then(res => {
          if (res) {
            this.list = res.data;
            this.$store.commit('setEntityMenuList', res.data);
          }
          resolve(true);
        })
        .catch(() => resolve(false));
    });
  }
}
